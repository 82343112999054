<template>
  <section id="main-wrapper" style="min-height: 95vh" class="text-justify">
    <div class="container">
      <!-- End Navigation -->
      <div class="clearfix"></div>
      <!-- ============================================================== -->
      <!-- Top header  -->
      <!-- ============================================================== -->

      <!-- ============================ Page Title Start================================== -->
      <section class="page-title">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="breadcrumbs-wrap">
                <h1 class="breadcrumb-title">Privacy Policy</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ============================ Page Title End ================================== -->

      <!-- ============================ Agency List Start ================================== -->
      <section>
        <p>This Application collects some Personal Data from its Users.</p>
        <p></p>
        <p>
          This document can be printed for reference by using the print command
          in the settings of any browser.
        </p>
        <p><strong>971 Tutors and Data Controller</strong></p>
        <p><strong>Dubai - United Arab Emirates</strong></p>
        <p><strong></strong></p>
        <p><strong>Owner contact email:</strong>info</p>
        <p><strong>Types of Data collected</strong></p>
        <p>
          Among the types of Personal Data that this Application collects, by
          itself or through third parties, there are: Contacts permission;
          Camera permission; Microphone permission; Social media accounts
          permission; Approximate location permission (continuous); Cookies;
          Usage Data; email address; password; unique device identifiers for
          advertising (Google Advertiser ID or IDFA, for example).
        </p>
        <p>
          Complete details on each type of Personal Data collected are provided
          in the dedicated sections of this privacy policy or by specific
          explanation texts displayed prior to the Data collection.<br />
          Personal Data may be freely provided by the User, or, in case of Usage
          Data, collected automatically when using this Application.<br />
          Unless specified otherwise, all Data requested by this Application is
          mandatory and failure to provide this Data may make it impossible for
          this Application to provide its services. In cases where this
          Application specifically states that some Data is not mandatory, Users
          are free not to communicate this Data without consequences to the
          availability or the functioning of the Service.<br />
          Users who are uncertain about which Personal Data is mandatory are
          welcome to contact the Owner.<br />
          Any use of Cookies &ndash; or of other tracking tools &ndash; by this
          Application or by the owners of third-party services used by this
          Application serves the purpose of providing the Service required by
          the User, in addition to any other purposes described in the present
          document and in the Cookie Policy, if available.
        </p>
        <p>
          Users are responsible for any third-party Personal Data obtained,
          published or shared through this Application and confirm that they
          have the third party's consent to provide the Data to the Owner.
        </p>
        <h2>Mode and place of processing the data</h2>
        <h3>Methods of processing </h3>
        <p>
          The 971 Tutors takes appropriate security measures to prevent
          unauthorized access, disclosure, modification, or unauthorized
          destruction of the Data.<br />
          The Data processing is carried out using computers and/or IT enabled
          tools, following organizational procedures and modes strictly related
          to the purposes indicated. In addition to the Owner, in some cases,
          the Data may be accessible to certain types of persons in charge,
          involved with the operation of this Application (administration,
          sales, marketing, legal, system administration) or external parties
          (such as third-party technical service providers, mail carriers,
          hosting providers, IT companies, communications agencies) appointed,
          if necessary, as Data Processors by the Owner. The updated list of
          these parties may be requested from the Owner at any time.
        </p>
        <h3>Legal basis of processing</h3>
        <p>
          The 971 Tutors may process Personal Data relating to Users if one of
          the following applies:
        </p>
        <ul>
          <li>
            Users have given their consent for one or more specific purposes.
            Note: Under some legislations the Owner may be allowed to process
            Personal Data until the User objects to such processing
            (&ldquo;opt-out&rdquo;), without having to rely on consent or any
            other of the following legal bases. This, however, does not apply,
            whenever the processing of Personal Data is subject to European data
            protection law.
          </li>
          <li>
            provision of Data is necessary for the performance of an agreement
            with the User and/or for any pre-contractual obligations thereof.
          </li>
          <li>
            processing is necessary for compliance with a legal obligation to
            which the Owner is subject.
          </li>
          <li>
            processing is related to a task that is carried out in the public
            interest or in the exercise of official authority vested in the 971
            tutors.
          </li>
          <li>
            processing is necessary for the purposes of the legitimate interests
            pursued by 971 tutors or by a third party.
          </li>
        </ul>
        <p>
          In any case, 971 tutors will gladly help to clarify the specific legal
          basis that applies to the processing, and in particular whether the
          provision of Personal Data is a statutory or contractual requirement,
          or a requirement necessary to enter into a contract.
        </p>
        <h3>Place</h3>
        <p>
          The Data is processed at the Owner's operating offices and in any
          other places where the parties involved in the processing are
          located.<br />
          <br />
          Depending on the User's location, data transfers may involve
          transferring the User's Data to a country other than their own. To
          find out more about the place of processing of such transferred Data,
          Users can check the section containing details about the processing of
          Personal Data.
        </p>
        <p>
          Users are also entitled to learn about the legal basis of Data
          transfers to a country outside the European Union or to any
          international organization governed by public international law or set
          up by two or more countries, such as the UN, and about the security
          measures taken by 971 Tutors to safeguard their Data.<br />
          <br />
          If any such transfer takes place, Users can find out more by checking
          the relevant sections of this document or inquire with 971 Tutors
          using the information provided in the contact section.
        </p>
        <h3>Retention time</h3>
        <p>
          Personal Data shall be processed and stored for as long as required by
          the purpose they have been collected for.
        </p>
        <p>Therefore:</p>
        <ul>
          <li>
            Personal Data collected for purposes related to the performance of a
            contract between the Owner and the User shall be retained until such
            contract has been fully performed.
          </li>
          <li>
            Personal Data collected for the purposes of the Owner&rsquo;s
            legitimate interests shall be retained as long as needed to fulfill
            such purposes. Users may find specific information regarding the
            legitimate interests pursued by the Owner within the relevant
            sections of this document or by contacting the Owner.
          </li>
        </ul>
        <p>
          971 Tutors may be allowed to retain Personal Data for a longer period
          whenever the User has given consent to such processing, as long as
          such consent is not withdrawn. Furthermore, 971 Tutors may be obliged
          to retain Personal Data for a longer period whenever required to do so
          for the performance of a legal obligation or upon order of an
          authority.<br />
          <br />
          Once the retention period expires, Personal Data shall be deleted.
          Therefore, the right to access, the right to erasure, the right to
          rectification and the right to data portability cannot be enforced
          after expiration of the retention period.
        </p>
        <h2>The purposes of processing</h2>
        <p>
          The Data concerning the User is collected to allow 971 tutors to
          provide its Service, comply with its legal obligations, respond to
          enforcement requests, protect its rights and interests (or those of
          its Users or third parties), detect any malicious or fraudulent
          activity, as well as the following: Device permissions for Personal
          Data access, Analytics, Registration and authentication, Access to
          third-party accounts, Registration and authentication provided
          directly by this Application and Advertising.
        </p>
        <h2>Facebook permissions asked by this application</h2>
        <p>
          This Application may ask for some Facebook permissions allowing it to
          perform actions with the User's Facebook account and to retrieve
          information, including Personal Data, from it. This service allows
          this Application to connect with the User's account on the Facebook
          social network, provided by Facebook Inc.
        </p>
        <p>
          For more information about the following permissions, refer to the<a
            href="https://developers.facebook.com/docs/facebook-login/permissions"
            >Facebook permissions documentation</a
          >and to the<a href="https://www.facebook.com/about/privacy/"
            >Facebook privacy policy</a
          >.
        </p>
        <p>The permissions asked are the following:</p>
        <h3>Basic information</h3>
        <p>
          By default, this includes certain User&rsquo;s Data such as id, name,
          picture, gender, and their locale. Certain connections of the User,
          such as the Friends, are also available. If the User has made more of
          their Data public, more information will be available.
        </p>
        <h3>Email</h3>
        <p>Provides access to the User's primary email address.</p>
        <h2>Device permissions for Personal Data access</h2>
        <p>
          Depending on the User's specific device, this Application may request
          certain permissions that allow it to access the User's device Data as
          described below.
        </p>
        <p>
          By default, these permissions must be granted by the User before the
          respective information can be accessed. Once the permission has been
          given, it can be revoked by the User at any time. In order to revoke
          these permissions, Users may refer to the device settings or contact
          the Owner for support at the contact details provided in the present
          document.<br />
          The exact procedure for controlling app permissions may be dependent
          on the User's device and software.
        </p>
        <p>
          Please note that the revoking of such permissions might impact the
          proper functioning of this Application.
        </p>
        <p>
          If User grants any of the permissions listed below, the respective
          Personal Data may be processed (i.e accessed to, modified, or removed)
          by this Application.
        </p>
        <h3>Approximate location permission (continuous)</h3>
        <p>
          Used for accessing the User's approximate device location. This
          Application may collect, use, and share User location Data in order to
          provide location-based services.
        </p>
        <h3>Camera permission</h3>
        <p>
          Used for accessing the camera or capturing images and video from the
          device.
        </p>
        <h3>Contacts permission</h3>
        <p>
          Used for accessing contacts and profiles on the User's device,
          including the changing of entries.
        </p>
        <h3>Microphone permission</h3>
        <p>
          Allows accessing and recording microphone audio from the User's
          device.
        </p>
        <h3>Social media accounts permission</h3>
        <p>
          Used for accessing the User's social media account profiles, such as
          Facebook and Twitter.
        </p>
        <p></p>
        <h2>The rights of Users</h2>
        <p>
          Users may exercise certain rights regarding their Data processed by
          971 Tutors.
        </p>
        <p>In particular, Users have the right to do the following:</p>
        <ul>
          <li>
            <strong>Withdraw their consent at any time.</strong>Users have the
            right to withdraw consent where they have previously given their
            consent to the processing of their Personal Data.
          </li>
          <li>
            <strong>Object to processing of their Data.</strong>Users have the
            right to object to the processing of their Data if the processing is
            carried out on a legal basis other than consent. Further details are
            provided in the dedicated section below.
          </li>
          <li>
            <strong>Access their Data.</strong>Users have the right to learn if
            Data is being processed by the Owner, obtain disclosure regarding
            certain aspects of the processing, and obtain a copy of the Data
            undergoing processing.
          </li>
          <li>
            <strong>Verify and seek rectification.</strong>Users have the right
            to verify the accuracy of their Data and ask for it to be updated or
            corrected.
          </li>
          <li>
            <strong>Restrict the processing of their Data.</strong>Users have
            the right, under certain circumstances, to restrict the processing
            of their Data. In this case, the Owner will not process their Data
            for any purpose other than storing it.
          </li>
          <li>
            <strong
              >Have their Personal Data deleted or otherwise removed.</strong
            >Users have the right, under certain circumstances, to obtain the
            erasure of their Data from the Owner.
          </li>
          <li>
            <strong
              >Receive their Data and have it transferred to another
              controller.</strong
            >Users have the right to receive their Data in a structured,
            commonly used and machine-readable format and, if technically
            feasible, to have it transmitted to another controller without any
            hindrance. This provision is applicable provided that the Data is
            processed by automated means and that the processing is based on the
            User's consent, on a contract which the User is part of or on
            pre-contractual obligations thereof.
          </li>
          <li>
            <strong>Lodge a complaint.</strong>Users have the right to bring a
            claim before their competent data protection authority.
          </li>
        </ul>
        <h3>Details about the right to object to processing</h3>
        <p>
          Where Personal Data is processed for a public interest, in the
          exercise of an official authority vested in 971 Tutors or for the
          purposes of the legitimate interests pursued by 971 Tutors, Users may
          object to such processing by providing a ground related to their
          particular situation to justify the objection.
        </p>
        <p>
          Users must know that, however, should their Personal Data be processed
          for direct marketing purposes, they can object to that processing at
          any time without providing any justification. To learn, whether 971
          Tutors is processing Personal Data for direct marketing purposes,
          Users may refer to the relevant sections of this document.
        </p>
        <h3>How to exercise these rights</h3>
        <p>
          Any requests to exercise User rights can be directed to the Owner
          through the contact details provided in this document. These requests
          can be exercised free of charge and will be addressed by 971 Tutors as
          early as possible and always within one month.
        </p>
        <h2>Additional information about Data collection and processing</h2>
        <h3>Legal action</h3>
        <p>
          The User's Personal Data may be used for legal purposes by the Owner
          in Court or in the stages leading to possible legal action arising
          from improper use of this Application or the related Services.<br />
          The User declares to be aware that 971 Tutors may be required to
          reveal personal data upon request of public authorities.
        </p>
        <h3>Additional information about User's Personal Data</h3>
        <p>
          In addition to the information contained in this privacy policy, this
          Application may provide the User with additional and contextual
          information concerning particular services or the collection and
          processing of Personal Data upon request.
        </p>
        <h3>System logs and maintenance</h3>
        <p>
          For operation and maintenance purposes, this Application and any
          third-party services may collect files that record interaction with
          this Application (System logs) use other Personal Data (such as the IP
          Address) for this purpose.
        </p>
        <h3>Information not contained in this</h3>
        <p>
          More details concerning the collection or processing of Personal Data
          may be requested from 971 Tutors at any time. Please see the contact
          information at the beginning of this document.
        </p>
        <h3>How &ldquo;Do Not Track&rdquo; requests are handled</h3>
        <p>
          This Application does not support &ldquo;Do Not Track&rdquo;
          requests.<br />
          To determine whether any of the third-party services it uses honour
          the &ldquo;Do Not Track&rdquo; requests, please read their privacy
          policies.
        </p>
        <h3>Changes to this privacy policy</h3>
        <p>
          The Owner reserves the right to make changes to this privacy policy at
          any time by notifying its Users on this page and possibly within this
          Application and/or - as far as technically and legally feasible -
          sending a notice to Users via any contact information available to the
          Owner. It is strongly recommended to check this page often, referring
          to the date of the last modification listed at the bottom.<br />
          <br />
          Should the changes affect processing activities performed on the basis
          of the User&rsquo;s consent, 971 Tutors shall collect new consent from
          the User, where required.
        </p>
        <p></p>
        <h4>Personal Data (or Data)</h4>
        <p>
          Any information that directly, indirectly, or in connection with other
          information &mdash; including a personal identification number &mdash;
          allows for the identification or identifiability of a natural person.
        </p>
        <h4>Usage Data</h4>
        <p>
          Information collected automatically through this Application (or
          third-party services employed in this Application), which can include:
          the IP addresses or domain names of the computers utilized by the
          Users who use this Application, the URI addresses (Uniform Resource
          Identifier), the time of the request, the method utilized to submit
          the request to the server, the size of the file received in response,
          the numerical code indicating the status of the server's answer
          (successful outcome, error, etc.), the country of origin, the features
          of the browser and the operating system utilized by the User, the
          various time details per visit (e.g., the time spent on each page
          within the Application) and the details about the path followed within
          the Application with special reference to the sequence of pages
          visited, and other parameters about the device operating system and/or
          the User's IT environment.
        </p>
        <h4>User</h4>
        <p>
          The individual using this Application who, unless otherwise specified,
          coincides with the Data Subject.
        </p>
        <h4>Data Subject</h4>
        <p>The natural person to whom the Personal Data refers.</p>
        <h4>Data Processor (or Data Supervisor)</h4>
        <p>
          The natural or legal person, public authority, agency or other body
          which processes Personal Data on behalf of the Controller, as
          described in this privacy policy.
        </p>
        <h4>Data Controller (or 971 Tutors)</h4>
        <p>
          The natural or legal person, public authority, agency or other body
          which, alone or jointly with others, determines the purposes and means
          of the processing of Personal Data, including the security measures
          concerning the operation and use of this Application. The Data
          Controller, unless otherwise specified, is the Owner of this
          Application.
        </p>
        <h4>This Application</h4>
        <p>
          The means by which the Personal Data of the User is collected and
          processed.
        </p>
        <h4>Service</h4>
        <p>
          The service provided by this Application as described in the relative
          terms (if available) and on this site/application.
        </p>
        <p>
          Unless otherwise specified, all references made within this document
          to the United Arab Emirates include all current member states to
          United Arab Emirates
        </p>
        <h4>Cookie</h4>
        <p>
          Cookies are Trackers consisting of small sets of data stored in the
          User's browser.
        </p>
        <h4>Tracker</h4>
        <p>
          Tracker indicates any technology - e.g. Cookies, unique identifiers,
          web beacons, embedded scripts, e-tags and fingerprinting - that
          enables the tracking of Users, for example by accessing or storing
          information on the User&rsquo;s device.
        </p>
        <p><br></p>
        <p><i>Last Modified: April 12, 2021</i></p>
      </section>
      <!-- ============================ Agency List End ================================== -->
    </div>
  </section>
</template>

<script>
export default {
  name: "cmp-privacypolicy",
  mounted() {
    setTimeout(() => {
      this.$store.commit("md_fire/mt_setLoading", false);
    }, 500);
  },
};
</script>

<style>
</style>